@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.userContent {
  display: flex;
  // justify-content: flex-start;

  .icon {
    min-width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 32px;
  }

  > .userInfo {
    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #0c0c0c;
      line-height: 28px;
    }
    .labelDiv {
      display: flex;
      > div {
        color: #ffffff;
        padding: 1px 10px;
        margin-left: 10px;
        border-radius: 8px;
        background-color: rgba(114, 92, 255, 1);
      }
    }

    > span {
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
    }

    > .btn {
      margin-top: 20px;
      height: 38px;
      background: linear-gradient(180deg, #fcfbff 0%, rgba(218, 215, 255, 0.8) 100%);
      box-shadow: 0px 4px 12px 0px rgba(114, 92, 255, 0.2);
      border-radius: 27px;
      opacity: 1;
      border: 1px solid #ffffff;
      color: var(--app-primary-color);
    }

    .retrack {
      background: linear-gradient(180deg, #725cff 0%, rgba(141, 131, 255, 0.8) 100%);
      color: #fff;
    }

    .unlock {
      background: linear-gradient( 180deg, #725CFF 0%, #9C8CFF 100%), linear-gradient( 180deg, #725CFF 0%, rgba(141,131,255,0.8) 100%);
      box-shadow: 0px 4px 12px 0px rgba(114,92,255,0.2);
      color: #fff;
      border: none;
      font-size: 14px;
      line-height: 14px;
    }

    > .disAbleBtn {
      margin-top: 20px;
      height: 38px;
      border-radius: 27px;
      opacity: 1;
      border: 1px solid #d9d9d9;
      color: #0c0c0c;
    }
  }
}

.dataContent {
  align-items: center;

  > .dataTitle {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    height: 22px;
    white-space: nowrap;
  }

  > .dataInfo {
    display: flex;
    align-items: center;
    // justify-content: center;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    span {
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
}

.groundContent {
  // box-shadow: 0px 2px 12px 0px #e3e6f3;
  border-radius: 12px;
  // padding: 32px;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  position: relative;

  .reportInfoContainer {
    margin-top: 12px;
  }

  .statusTag {
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px 14px;
    background: lighten(#725cff, 25%);
    border-bottom-left-radius: 12px;
    border-top-right-radius: 12px;
    color: var(--app-primary-color);
  }

  > .delete {
    position: absolute;
    top: 0;
    right: 0;

    > .normalContainer {
      padding: 7px 14px;
      height: 36px;
      background: #d0c5ff;
      color: var(--app-primary-color);
      border-bottom-left-radius: 12px;
      border-top-right-radius: 12px;
      cursor: pointer;
    }

    > .otherContainer {
      padding: 0 18px;
      height: 36px;
      line-height: 36px;
      background: #f1f1f1;
      color: #999999;
      font-size: 14px;
      border-bottom-left-radius: 12px;
      border-top-right-radius: 12px;
      cursor: not-allowed;
    }
  }

  > .dataBackContent {
    display: flex;
    padding-left: 15px;
    align-items: center;
    cursor: pointer;
    width: 100%;
    > .dataEmptyContent {
      width: 100%;

      > div {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }
    }

    .dataListRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 31px 0;
      border-bottom: 1px solid #f1efff;
    }

    .dataListBackContent {
      display: block;
      width: 100%;

      .dataListContent {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;

        &:last-child {
          margin-top: 18px;
        }
      }
      .tooltipCard {
        background: #fff;
      }
      .titleIcon {
        color: var(--app-primary-color);
      }
    }

    > .arrowIcon {
      color: var(--app-text-30-color);
    }
  }

  > .bottomBtn {
    display: none;
    margin: 50px 45px 20px;
    height: 38px;
    background: linear-gradient(180deg, #fcfbff 0%, rgba(218, 215, 255, 0.8) 100%);
    box-shadow: 0px 4px 12px 0px rgba(114, 92, 255, 0.2);
    border-radius: 27px;
    opacity: 1;
    border: 1px solid #ffffff;
    color: var(--app-primary-color);
  }

  > .disAbleBottomBtn {
    display: none;
    margin: 50px 45px 20px;
    height: 38px;
    border-radius: 27px;
    opacity: 1;
    border: 1px solid #d9d9d9;
    color: #0c0c0c;
  }

  > .mobileReportContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    padding: 2px 14px;
    height: 36px;
    // line-height: 1;
    background: #d0c5ff;
    color: var(--app-primary-color);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 100%;
    text-align: center;
  }

  > .nextReportContainer {
    position: absolute;
    top: 8px;
    left: 8px;
    background: #f0edff;
    color: #725cff;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 2px 8px;
    border-radius: 16px;

    > .loading {
      animation: rotate 2s linear infinite;
    }
  }
}

@media screen and (min-width: 899px) {
  .userContent {
    display: flex;
    .icon {
      margin-right: 32px;
    }

    > .userInfo .btn .disAbleBtn {
      display: block;
    }
  }

  .groundContent {
    grid-template-columns: 1fr 1fr;

    > .dataBackContent {
      margin-top: 0;
      padding-left: 15px;
    }

    > .bottomBtn .disAbleBottomBtn {
      display: none;
    }
  }
}

@media screen and (max-width: 900px) {
  .userContent {
    display: block;
    text-align: center;
    .icon {
      // margin-right: 0;
      margin: auto;
      width: 80px;
      max-width: 80px;
      min-width: 80px;
      min-height: 80px;
      max-height: 80px;
    }

    > .userInfo .btn .disAbleBtn {
      display: none;
    }

    > .userInfo .labelDiv {
      justify-content: center;
    }
  }

  .groundContent {
    grid-template-columns: 1fr;
    padding: 25px;
    // padding: 25px !important;
    > .dataBackContent {
      margin-top: 32px;
      padding-left: 0;

      .dataListRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        padding: 15px 0;
        // display: flex;
        // grid-template-columns: repeat(3, 1ft);
      }
    }

    .dataListBackContent {
      .dataListContent {
        .dataContent {
          .dataTitle {
            font-size: 12px;
          }
        }
      }
    }

    > .bottomBtn .disAbleBottomBtn {
      display: inline-block;
    }
  }
}

